import { Drawer } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ActivityStatusFilter } from '../../components/ActivityStatusFilter';
import { DeliveryModeFeatureFilter } from '../../components/DeliveryModeFeatureFilter';
import { DeviceInfo } from '../../components/DeviceInfo/DeviceInfo';
import { DeviceTable, TableSortModel } from '../../components/DeviceTable';
import { HeaderSpace } from '../../components/GlobalHeader';
import { IsInDeliveryModeFilter } from '../../components/IsInDeliveryModeFilter';
import { SearchField } from '../../components/SearchField';
import { UsageOrganizationSearch } from '../../components/UsageOrganizationSearch';
import { useDevices } from '../../hooks/useDevices';
import { useIsScanDeviceAdmin } from '../../hooks/useIsScanDeviceAdmin';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useTracking } from '../../hooks/useTracking';
import { useUsageOrganizations } from '../../hooks/useUsageOrganizations';
import {
  ActivityStatus,
  BoolFilterQueryParam,
  DeliveryModeState,
  Device,
  InternalDeviceResponse,
  unwrapInternalDeviceResponse,
} from '../../models/device';
import { UnauthorizedError } from '../Error/UnauthorizedError';
import { UnknownError } from '../Error/UnknownError';

import { Layout } from './layout';

export const DeviceOverviewContent = () => {
  const {
    paginationModel,
    setPaginationModel,
    sortModel,
    setSortModel,
    filterModel,
    setFilterModel,
  } = useQueryParams();
  const { data, error, isLoading, isRetrying, refetchDevices } = useDevices(
    paginationModel,
    sortModel,
    filterModel,
  );
  const { data: usageOrganizations, refetchUsageOrganizations } = useUsageOrganizations();

  const isScanDeviceAdmin = useIsScanDeviceAdmin();

  const { t } = useTranslation();
  const { track } = useTracking();

  const [deviceCount, setDeviceCount] = useState<number | undefined>(undefined);

  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);

  const [wasDeviceUpdateSuccessful, setWasDeviceUpdateSuccessful] = useState(false);

  useEffect(() => {
    if (!isLoading && data) setDeviceCount(data?.totalElements);
  }, [data, isLoading]);

  useEffect(() => {
    if (
      !isLoading &&
      deviceCount === 0 &&
      filterModel.usageOrganizationName &&
      wasDeviceUpdateSuccessful
    ) {
      setFilterModel({ ...filterModel, usageOrganizationName: null });
      setWasDeviceUpdateSuccessful(false);
    }
  }, [isLoading, deviceCount, setFilterModel, filterModel, wasDeviceUpdateSuccessful]);

  const handleSortModelChange = (sortModel: TableSortModel) => {
    track('deviceOverview.sortOrderChanged', { sortModel });

    setSortModel(sortModel);
  };

  const serialNumberSearch = (
    <SearchField
      label={t('deviceOverview.deviceFilter.label')}
      onChange={(searchTerms) => {
        track('deviceOverview.filterChanged.serialNumber', { searchTerms });

        setFilterModel({ ...filterModel, serialNumber: searchTerms });
      }}
      placeholder={t('deviceOverview.deviceFilter.placeholder')}
      searchTerms={filterModel.serialNumber}
    />
  );

  const clientOrganizationSearch = (
    <SearchField
      label={t('deviceOverview.clientFilter.label')}
      onChange={(searchTerms) => {
        track('deviceOverview.filterChanged.client', { searchTerms });

        setFilterModel({ ...filterModel, client: searchTerms });
      }}
      placeholder={t('deviceOverview.clientFilter.placeholder')}
      searchTerms={filterModel.client}
    />
  );

  const projectSearch = (
    <SearchField
      label={t('deviceOverview.projectFilter.label')}
      onChange={(searchTerms) => {
        track('deviceOverview.filterChanged.project', { searchTerms });

        setFilterModel({ ...filterModel, project: searchTerms });
      }}
      placeholder={t('deviceOverview.projectFilter.placeholder')}
      searchTerms={filterModel.project}
    />
  );

  const activityStatusFilter = (
    <ActivityStatusFilter
      activityStatus={filterModel.activityStatus as ActivityStatus & null}
      onChange={(activityStatus) => {
        track('deviceOverview.filterChanged.activityStatus', { activityStatus });

        setFilterModel({ ...filterModel, activityStatus: activityStatus });
      }}
    />
  );

  const isInDeliveryModeFilter = (
    <IsInDeliveryModeFilter
      isInDeliveryMode={filterModel.deliveryModeState as DeliveryModeState & null}
      onChange={(isInDeliveryMode) => {
        track('deviceOverview.filterChanged.isInDeliveryMode', { isInDeliveryMode });

        setFilterModel({ ...filterModel, deliveryModeState: isInDeliveryMode });
      }}
    />
  );

  const deliveryModeFeatureFilter = (
    <DeliveryModeFeatureFilter
      deliveryModeFeatureEnabled={
        filterModel.deliveryModeFeatureEnabled as BoolFilterQueryParam & null
      }
      onChange={(deliveryModeFeatureEnabled) => {
        track('deviceOverview.filterChanged.deliveryModeFeatureEnabled', {
          deliveryModeFeatureEnabled,
        });

        setFilterModel({ ...filterModel, deliveryModeFeatureEnabled });
      }}
    />
  );

  const usageOrganizationSearch = (
    <UsageOrganizationSearch
      initialValue={filterModel.usageOrganizationName}
      items={usageOrganizations?.items || []}
      onChange={(usageOrganization) => {
        setFilterModel({ ...filterModel, usageOrganizationName: usageOrganization });
      }}
    />
  );

  const filtersActive = filterModel.serialNumber.length > 0 || filterModel.activityStatus !== null;

  const devices = data?.content
    ? isScanDeviceAdmin
      ? (data?.content as InternalDeviceResponse[]).map(unwrapInternalDeviceResponse)
      : (data?.content as Device[])
    : [];

  const deviceTable = (
    <DeviceTable
      deviceCount={deviceCount ?? 0}
      devices={devices ?? []}
      filtersActive={filtersActive}
      hideFooterPagination={deviceCount === undefined}
      isLoading={isLoading || isRetrying}
      onPaginationModelChange={setPaginationModel}
      onRowClick={(device) => setSelectedDevice(device)}
      onSortModelChange={handleSortModelChange}
      paginationModel={paginationModel}
      refetchDevices={() => {
        refetchDevices();
        refetchUsageOrganizations();
        setWasDeviceUpdateSuccessful(true);
      }}
      sortModel={sortModel}
    />
  );

  const sidebar = (
    <Drawer
      anchor={'right'}
      onClose={() => setSelectedDevice(null)}
      open={!!selectedDevice}
      slotProps={{
        backdrop: { invisible: true },
      }}
    >
      <HeaderSpace />
      {selectedDevice && (
        <DeviceInfo device={selectedDevice} onClose={() => setSelectedDevice(null)} />
      )}
    </Drawer>
  );

  if (error?.status === 403 || error?.status === 401) {
    return <UnauthorizedError />;
  }

  if (error && !isRetrying) {
    return <UnknownError errorKey={error.key} id={error.id} />;
  }

  return (
    <Layout
      activityStatusFilter={activityStatusFilter}
      clientSearch={clientOrganizationSearch}
      deliveryModeFeatureFilter={deliveryModeFeatureFilter}
      deviceTable={deviceTable}
      heading={t('deviceOverview.heading')}
      isInDeliveryModeFilter={isInDeliveryModeFilter}
      projectSearch={projectSearch}
      serialNumberSearch={serialNumberSearch}
      sidebar={sidebar}
      usageOrganizationSearch={usageOrganizationSearch}
    />
  );
};
