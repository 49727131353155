import { CircleInformationOutline } from '@deepup/icons';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Device } from 'src/models/device';

const DeviceGroupTooltip = ({
  devices,
  children,
}: {
  devices: Device[];
  children: React.ReactNode;
}) => (
  <Tooltip arrow placement="top" title={devices.map((device) => device.serialNumber).join(', ')}>
    <Box component="span">{children}</Box>
  </Tooltip>
);

const DeviceGroupHeader = ({ devices, title }: { devices: Device[]; title: string }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <DeviceGroupTooltip devices={devices}>
      <CircleInformationOutline color="primary" height={24} width={24} />
    </DeviceGroupTooltip>
    <Typography>{title}</Typography>
  </Box>
);

interface SetDeliveryModeFeatureProps {
  devices: Device[];
  setDeliveryModeFeatureStateMap: (
    fn: (prevState: Record<string, boolean>) => Record<string, boolean>,
  ) => void;
}

export const SetDeliveryModeFeature: React.FC<SetDeliveryModeFeatureProps> = ({
  devices,
  setDeliveryModeFeatureStateMap,
}) => {
  const { t } = useTranslation();

  const deviceGroups = {
    enabled: devices.filter((device) => device.deliveryModeFeatureEnabled === true),
    disabled: devices.filter((device) => device.deliveryModeFeatureEnabled === false),
  };

  const handleDevicesChange = (devices: Device[], value: boolean) => {
    setDeliveryModeFeatureStateMap((prevState: Record<string, boolean>) => {
      const updates = devices.reduce<Record<string, boolean>>(
        (acc, device) => ({
          ...acc,
          [device.id]: value,
        }),
        {},
      );

      return { ...prevState, ...updates };
    });
  };

  const removeDevices = (devices: Device[]) => {
    setDeliveryModeFeatureStateMap((prevState: Record<string, boolean>) => {
      const newState = { ...prevState };

      devices.forEach((device) => {
        delete newState[device.id];
      });

      return newState;
    });
  };

  return (
    <Stack gap={2}>
      <Typography variant="h6">
        {t('deliveryModeUpdateDrawer.setDeliveryModeFeatureOption')}
      </Typography>

      {deviceGroups.enabled.length > 0 && (
        <Box>
          <DeviceGroupHeader
            devices={deviceGroups.enabled}
            title={t('deliveryModeUpdateDrawer.enabledDevices', {
              count: deviceGroups.enabled.length,
            })}
          />
          <RadioGroup defaultValue="true" sx={{ px: 4, pb: 2, pt: 1 }}>
            <FormControlLabel
              control={<Radio />}
              label={`${t('deviceOverview.deliveryModeFeatureEnabled.true')} (${t('deliveryModeUpdateDrawer.current')})`}
              onChange={() => removeDevices(deviceGroups.enabled)}
              value="true"
            />
            <FormControlLabel
              control={<Radio />}
              label={t('deviceOverview.deliveryModeFeatureEnabled.false')}
              onChange={() => handleDevicesChange(deviceGroups.enabled, false)}
              value="false"
            />
          </RadioGroup>
        </Box>
      )}

      {deviceGroups.disabled.length > 0 && (
        <Box>
          <DeviceGroupHeader
            devices={deviceGroups.disabled}
            title={t('deliveryModeUpdateDrawer.disabledDevices', {
              count: deviceGroups.disabled.length,
            })}
          />
          <RadioGroup defaultValue="false" sx={{ px: 4, pb: 2, pt: 1 }}>
            <FormControlLabel
              control={<Radio />}
              label={`${t('deviceOverview.deliveryModeFeatureEnabled.false')} (${t('deliveryModeUpdateDrawer.current')})`}
              onChange={() => removeDevices(deviceGroups.disabled)}
              value="false"
            />
            <FormControlLabel
              control={<Radio />}
              label={t('deviceOverview.deliveryModeFeatureEnabled.true')}
              onChange={() => handleDevicesChange(deviceGroups.disabled, true)}
              value="true"
            />
          </RadioGroup>
        </Box>
      )}
    </Stack>
  );
};
