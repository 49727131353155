import { CircleQuestionmarkOutline, ScanDeviceBOutline } from '@deepup/icons';
import { Icon, Tooltip, useTheme } from '@mui/material';
import { useFlags } from 'flagsmith/react';
import { DateTime } from 'luxon';
import { Trans, useTranslation } from 'react-i18next';

import { Device } from 'src/models/device';

import { Section, SubSection } from './CommonSections';

export const DeviceDetails = ({ device }: { device: Device }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const iconSize = theme.spacing(2.5);
  const { latestAddress, latestAsset, deliveryModeState, activityStatus } = device;
  const { delivery_mode_enabled } = useFlags(['delivery_mode_enabled']);

  const { usageAgreement } = device;
  const isDeliveryModeEnabled = delivery_mode_enabled.enabled;

  const latestUpdate =
    latestAsset?.recordedAt && latestAddress?.updatedAt
      ? new Date(latestAsset.recordedAt) > new Date(latestAddress.updatedAt)
        ? latestAsset.recordedAt
        : latestAddress.updatedAt
      : latestAsset?.recordedAt || latestAddress?.updatedAt;

  const transformedActivityStatusInfo = (
    <Trans
      components={[<br key="br" />]}
      i18nKey={`deviceOverview.deviceTable.columns.activityStatus.headerInfo`}
    />
  );

  return (
    <Section icon={<ScanDeviceBOutline fontSize={23} />} title={t('deviceInfo.aboutScanner')}>
      <SubSection
        content={t(`deviceOverview.activityStatus.${activityStatus}`)}
        icon={
          <Tooltip title={transformedActivityStatusInfo}>
            <Icon>
              <CircleQuestionmarkOutline height={iconSize} width={iconSize} />
            </Icon>
          </Tooltip>
        }
        title={t('deviceOverview.deviceTable.columns.activityStatus.header')}
      />
      {latestAddress && (
        <SubSection
          content={`${latestAddress.city}, ${latestAddress.street}`}
          title={t('deviceInfo.lastPosition')}
        />
      )}
      {latestUpdate && (
        <SubSection
          content={DateTime.fromISO(latestUpdate).toLocaleString(DateTime.DATETIME_MED, {
            locale: i18n.language,
          })}
          title={t('deviceInfo.lastUpdate')}
        />
      )}

      {isDeliveryModeEnabled && (
        <SubSection
          content={t(`deviceOverview.deliveryModeState.${deliveryModeState}`)}
          title={t('deviceOverview.deviceTable.columns.deliveryModeState.header')}
        />
      )}
      {device.latestAsset?.project?.name && (
        <SubSection content={device.latestAsset?.project?.name} title={t('deviceInfo.project')} />
      )}
      {device.clientOrganization?.name && (
        <SubSection
          content={device.clientOrganization?.name}
          title={t('deviceInfo.organization')}
        />
      )}
      {usageAgreement?.createdAt && (
        <SubSection
          content={DateTime.fromISO(usageAgreement.createdAt).toLocaleString(
            DateTime.DATETIME_MED,
            {
              locale: i18n.language,
            },
          )}
          title={t('deviceInfo.createdAt')}
        />
      )}
    </Section>
  );
};
