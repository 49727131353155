import { Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DeliveryModeState } from '../../models/device';

export type Props = {
  isInDeliveryMode: DeliveryModeState | null;
  onChange: (isInDeliveryMode: DeliveryModeState | null) => void;
};

export const IsInDeliveryModeFilter = ({ isInDeliveryMode, onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <InputLabel>{t('deviceOverview.isInDeliveryModeFilter.label')}</InputLabel>
      <Select
        aria-label={t('deviceOverview.isInDeliveryModeFilter.ariaLabel')}
        label={t('deviceOverview.isInDeliveryModeFilter.label')}
        onChange={({ target: { value } }) =>
          onChange((value === 'all' ? null : value) as DeliveryModeState | null)
        }
        sx={{ display: 'flex' }}
        value={isInDeliveryMode ?? 'all'}
      >
        <MenuItem value={'all'}>{t(`deviceOverview.isInDeliveryMode.all`)}</MenuItem>
        <Divider />
        <MenuItem value={'DELIVERY_MODE_STATE_ON'}>
          {t(`deviceOverview.isInDeliveryMode.on`)}
        </MenuItem>
        <MenuItem value={'DELIVERY_MODE_STATE_OFF'}>
          {t(`deviceOverview.isInDeliveryMode.off`)}
        </MenuItem>
        <MenuItem value={'DELIVERY_MODE_STATE_IN_REVIEW'}>
          {t(`deviceOverview.isInDeliveryMode.inReview`)}
        </MenuItem>
      </Select>
    </FormControl>
  );
};
