import { Divider, Stack } from '@mui/material';

import { Device } from 'src/models/device';

import { DeviceDetails } from './DeviceDetails';
import { DeviceInfoHeader } from './DeviceInfoHeader';
import { UsageOrganizationInfo } from './UsageOrganizationInfo';

type DeviceInfoProps = {
  device: Device;
  onClose: () => void;
};

export const DeviceInfo = ({ device, onClose }: DeviceInfoProps) => {
  const { serialNumber, usageAgreement } = device;

  return (
    <Stack data-testid="device-info-sidebar" sx={{ minWidth: '30rem', overflowX: 'hidden' }}>
      <DeviceInfoHeader onClose={onClose} title={serialNumber} />
      <Divider />
      {usageAgreement && (
        <>
          <UsageOrganizationInfo usageAgreement={usageAgreement} />
          <Divider />
        </>
      )}
      <DeviceDetails device={device} />
      <Divider />
    </Stack>
  );
};
