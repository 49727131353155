import { useEffect, useState } from 'react';
import useSWR from 'swr';

import type { TablePaginationModel, TableSortModel } from '../components/DeviceTable';
import type { Device, InternalDeviceResponse } from '../models/device';
import type { PaginatedServerResponse } from '../types/paginatedServerResponse';

import { useEnvironment } from './useEnvironment';
import { useErrorTracking } from './useErrorTracking';
import { useIsScanDeviceAdmin } from './useIsScanDeviceAdmin';
import { useJsonFetcher } from './useJsonFetcher';
import { type FilterModel } from './useQueryParams';

const errorRetryCount = 2;

const transformUrlSortModelForApi = ({ field, sort }: TableSortModel): string[] => {
  switch (field) {
    case 'clientOrganization':
      return [`clientOrganization_name,${sort}`];
    case 'project':
      return [`latestAsset_project_name,${sort}`];
    case 'address':
      return [`latestAddress_city,${sort}`, `latestAddress_street,${sort}`];
    case 'activityStatus':
      return [`latestAsset_latestAssetRecordedAt,${sort}`];
    case 'usageOrganization':
      return [`usage_organization_name,${sort}`];
    default:
      return [`${field},${sort}`];
  }
};

const transformUrlFilterModelForApi = (filterModel: FilterModel): [string, string][] => {
  const filterModels: [string, string][] = [];

  Object.entries(filterModel).forEach(([key, value]) => {
    if (value && value?.length > 0)
      filterModels.push([key, Array.isArray(value) ? value.join(' ') : value]);
  });

  return filterModels;
};

export const useDevices = (
  { page, pageSize }: TablePaginationModel,
  urlSortModel: TableSortModel,
  filterModel: FilterModel,
) => {
  const { checkResponse } = useErrorTracking();
  const { apiUrl } = useEnvironment();
  const isScanDeviceAdmin = useIsScanDeviceAdmin();

  const [retryCount, setRetryCount] = useState<number>(errorRetryCount);

  const sortModels: ['sort', string][] = transformUrlSortModelForApi(urlSortModel).map(
    (apiSortModel) => ['sort', apiSortModel],
  );

  const filterModels = transformUrlFilterModelForApi(filterModel);

  const filterString = new URLSearchParams([
    ['page', page?.toString()],
    ['size', pageSize?.toString()],
    ...sortModels,
    ...filterModels,
  ]);

  const jsonFetcher = useJsonFetcher<PaginatedServerResponse<Device | InternalDeviceResponse>>();

  const pathname = isScanDeviceAdmin ? 'internal-api' : 'api';
  const url = `${apiUrl}/${pathname}/device-management/v0/scanners?${filterString}`;

  const response = useSWR(
    isScanDeviceAdmin !== undefined && !!pageSize ? [url] : null,
    jsonFetcher,
    {
      refreshInterval: 60000,
      errorRetryInterval: 1000,
      errorRetryCount,
    },
  );

  checkResponse(response);

  useEffect(
    () =>
      setRetryCount((currentRetryCount) => {
        if (response.error) {
          return currentRetryCount - 1;
        }

        return errorRetryCount;
      }),
    [response.error],
  );

  return {
    ...response,
    isRetrying: response.error && retryCount > 0,
    refetchDevices: response.mutate,
  };
};
