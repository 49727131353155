import { useIsDeepUpUser } from './useIsDeepUpUser';

export const useIsScanDeviceAdmin = (): boolean | undefined => {
  // For the moment the scanDeviceAdmin is in FE the same as DeepUpUser.
  // On the backend there is a permission scan-device-admin, that for the moment cannot be queried.
  // If user tries to get/post data it will get 403
  const isDeepUpUser = useIsDeepUpUser();

  return isDeepUpUser;
};
