import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import type {
  GridColDef,
  GridColumnResizeParams,
  GridPaginationModel,
  GridRowSelectionModel,
  GridSortDirection,
  GridSortItem,
} from '@mui/x-data-grid';
import { DataGrid, DataGridProps, GridFooter } from '@mui/x-data-grid';
import { deDE, enUS } from '@mui/x-data-grid/locales';
import { useFlags, useFlagsmith } from 'flagsmith/react';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useIsScanDeviceAdmin } from '../../hooks/useIsScanDeviceAdmin';
import type { Device } from '../../models/device';
import {
  ActionOptions,
  DeliveryModeUpdateDrawer,
} from '../DeliveryModeUpdateDrawer/DeliveryModeUpdateDrawer';

import { HeaderCell } from './Cells/HeaderCell';
import { HeaderCellWithInfo } from './Cells/HeaderCellWithInfo';
import { Footer } from './Footer';
import { NoRowsOverlay } from './NoRowsOverlay';
import { TableRows } from './TableRows';
import { DeviceTableStyle, NotSortable } from './styles';

export type TablePaginationModel = GridPaginationModel;
export type TableSortModel = GridSortItem;
export type TableSortDirection = GridSortDirection;
export const pageSizeOptions = [25, 50, 100];

type ColumnConfig = Pick<GridColDef, 'field' | 'minWidth' | 'width' | 'sortable'> & {
  withInfoIcon?: boolean;
};

const defaultColumnConfigs: ColumnConfig[] = [
  { field: 'serialNumber', minWidth: 150, sortable: true, width: 180 },
  { field: 'clientOrganization', minWidth: 300, sortable: true, width: 300 },
  {
    field: 'usageOrganization',
    minWidth: 300,
    sortable: true,
    width: 300,
  },
  { field: 'project', minWidth: 300, width: 300, sortable: true, withInfoIcon: true },
  { field: 'address', minWidth: 200, width: 300, sortable: true, withInfoIcon: true },
  { field: 'activityStatus', minWidth: 160, width: 160, sortable: false, withInfoIcon: true },
  {
    field: 'deliveryModeState',
    minWidth: 180,
    width: 180,
    sortable: false,
  },
  {
    field: 'deliveryModeFeatureEnabled',
    minWidth: 180,
    width: 180,
    sortable: false,
  },
];

export type Props = {
  devices: Device[];
  deviceCount: number;
  isLoading: boolean;
  paginationModel: TablePaginationModel;
  sortModel: TableSortModel;
  onPaginationModelChange?: (paginationModel: TablePaginationModel) => void;
  onSortModelChange?: (sortModel: TableSortModel) => void;
  onRowClick?: (device: Device) => void;
  filtersActive: boolean;
  refetchDevices: () => void;
} & Pick<DataGridProps, 'hideFooterPagination'>;

export const DeviceTable = ({
  devices,
  deviceCount,
  isLoading,
  paginationModel,
  sortModel,
  onPaginationModelChange,
  onSortModelChange,
  filtersActive,
  hideFooterPagination,
  onRowClick,
  refetchDevices,
}: Props) => {
  const { t, i18n } = useTranslation();
  const isScanDeviceAdmin = useIsScanDeviceAdmin();

  const rows = TableRows(devices);

  const [columnConfigs, setColumnConfigs] = useState<ColumnConfig[]>(defaultColumnConfigs);

  const [updateOption, setUpdateOption] = useState<ActionOptions>(null);

  const [selectedSelectionModel, setSelectedSelectionModel] = useState<GridRowSelectionModel>([]);

  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);

  const { delivery_mode_enabled } = useFlags(['delivery_mode_enabled']);

  const { isLoading: isFlagsmithLoading } = useFlagsmith();

  const isDeliveryModeEnabled = delivery_mode_enabled.enabled;

  const [visibilityColumnModel, setVisibilityColumnModel] = useState({
    deliveryModeFeatureEnabled: false,
    clientOrganization: false,
    deliveryModeState: false,
    usageOrganization: false,
  });

  useEffect(() => {
    setVisibilityColumnModel({
      deliveryModeFeatureEnabled: !!isScanDeviceAdmin && isDeliveryModeEnabled,
      clientOrganization: !!isScanDeviceAdmin,
      deliveryModeState: isDeliveryModeEnabled,
      usageOrganization: isDeliveryModeEnabled,
    });
  }, [isScanDeviceAdmin, isDeliveryModeEnabled]);

  const columns: GridColDef[] = useMemo(
    () =>
      columnConfigs.map(({ field, minWidth, width, withInfoIcon, sortable }) => ({
        field,
        minWidth,
        width,
        sortable,
        renderCell: ({ value }) => value,
        renderHeader: ({ field }) => {
          const caption = t(`deviceOverview.deviceTable.columns.${field}.header`);

          if (!withInfoIcon) {
            return <HeaderCell>{caption}</HeaderCell>;
          }

          const info = (
            <Trans
              components={[<br key="br" />]}
              i18nKey={`deviceOverview.deviceTable.columns.${field}.headerInfo`}
            />
          );

          return <HeaderCellWithInfo info={info}>{caption}</HeaderCellWithInfo>;
        },
      })),
    [t, columnConfigs],
  );

  const gridTranslations = useMemo(
    () => (i18n.language === 'de' ? deDE : enUS).components.MuiDataGrid.defaultProps.localeText,
    [i18n.language],
  );

  const handleColumnResize = ({
    colDef: { field, width: newColumnWidth },
  }: GridColumnResizeParams) =>
    setColumnConfigs((currentColumnConfigs) =>
      currentColumnConfigs.map((currentColumnConfig) =>
        currentColumnConfig.field === field
          ? { ...currentColumnConfig, width: newColumnWidth }
          : currentColumnConfig,
      ),
    );

  if (isScanDeviceAdmin === undefined || isFlagsmithLoading) {
    return null;
  }

  return (
    <>
      <DataGrid
        autoPageSize={!paginationModel.pageSize}
        checkboxSelection={isScanDeviceAdmin && isDeliveryModeEnabled}
        columnVisibilityModel={visibilityColumnModel}
        columns={columns}
        disableColumnMenu
        disableRowSelectionOnClick
        disableVirtualization
        hideFooterPagination={hideFooterPagination}
        loading={isLoading}
        localeText={gridTranslations}
        onColumnResize={handleColumnResize}
        onPaginationModelChange={(e) => {
          setSelectedDevices([]);
          setSelectedSelectionModel([]);
          onPaginationModelChange?.(e);
        }}
        onRowClick={(params) => {
          const id = params.row.id as string;
          const device = devices.find((device) => device.id === id)!;

          onRowClick?.(device);
        }}
        onRowSelectionModelChange={(selectionModel) => {
          const selectedDevicesForCurrentPage = selectionModel
            .map((id) => {
              const device = devices.find((device) => device.id === id);

              return device;
            })
            .filter((device) => device !== undefined) as Device[];

          setSelectedSelectionModel(selectionModel);
          setSelectedDevices(selectedDevicesForCurrentPage);
        }}
        onSortModelChange={(sortModel) => onSortModelChange?.(sortModel[0])}
        pageSizeOptions={pageSizeOptions}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        rowCount={deviceCount ?? 0}
        rowSelectionModel={selectedSelectionModel}
        rows={rows}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton',
          },
        }}
        slots={{
          noRowsOverlay: () => <NoRowsOverlay filtersActive={filtersActive} />,
          footer: () => (
            <Box alignItems="center" display="flex">
              {selectedDevices.length > 0 ? (
                <Footer
                  devices={devices}
                  onCancel={() => {
                    setSelectedDevices([]);
                    setSelectedSelectionModel([]);
                  }}
                  selectedDevices={selectedDevices}
                  setUpdateOption={setUpdateOption}
                />
              ) : (
                <Box flexGrow={1}>
                  <GridFooter />
                </Box>
              )}
            </Box>
          ),
        }}
        sortModel={sortModel ? [sortModel] : []}
        sortingMode="server"
        sortingOrder={['asc', 'desc']}
        sx={
          {
            ...DeviceTableStyle,
            ...(rows.length === 0 ? NotSortable : {}),
          } as SxProps
        }
      />
      <DeliveryModeUpdateDrawer
        currentOption={updateOption}
        devices={selectedDevices}
        handleClose={() => setUpdateOption(null)}
        onSuccessfulUpdate={() => {
          refetchDevices();
          setSelectedDevices([]);
          setSelectedSelectionModel([]);
        }}
        open={updateOption !== null}
      />
    </>
  );
};
