import { User } from '@auth0/auth0-react';
import mixpanel from 'mixpanel-browser';
import { useCallback } from 'react';

export type TrackingParams = {
  init: (mixpanelToken: string, user?: User) => void;
  track: (event: string, payload?: object) => void;
  trackPageView: (page: string, payload?: object) => void;
};

export const useTracking = (): TrackingParams => {
  const init = useCallback((mixpanelToken: string, user?: User) => {
    mixpanel.init(mixpanelToken, {
      persistence: 'localStorage',
      ignore_dnt: true,
      loaded: () => {
        const domain = user?.email?.split('@')[1] ?? 'Unknown';

        mixpanel.identify(domain);
      },
    });
  }, []);

  const track = useCallback((event: string, payload?: object) => {
    try {
      mixpanel.track(event, { ...payload });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-empty
    } catch (error) {}
  }, []);

  const trackPageView = useCallback((page: string, payload?: object) => {
    try {
      mixpanel.track_pageview({ page, ...payload });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-empty
    } catch (error) {}
  }, []);

  return { init, track, trackPageView };
};
