import { CheckmarkCircleFilled } from '@deepup/icons';
import {
  Alert,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Snackbar,
  Typography,
  useTheme,
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUpdateDevices } from '../../hooks/useUpdateDevices';
import { Device, RecipientConfirmationState } from '../../models/device';
import { Spacer } from '../../styles/Spacer';
import { DeviceInfoHeader } from '../DeviceInfo/DeviceInfoHeader';
import { HeaderSpace } from '../GlobalHeader';

import { LoadingSpinner } from './LoadingSpinner';
import { RetryFailedUpdates } from './RetryFailedUpdates';
import { SetDeliveryModeFeature } from './SetDeliveryModeFeature';
import { SetRecipientConfirmationState } from './SetRecipientConfirmationState';

interface DeliveryModeChangeProps {
  devices: Device[];
  onSuccessfulUpdate: () => void;
  open: boolean;
  handleClose: () => void;
  currentOption: ActionOptions;
}

export type ActionOptions = 'setRecipientConfirmation' | 'setDeliveryModeFeature' | null;

const setRecipientConfirmationKey = [
  '/internal-api/device-management/v1/recipient-confirmations/',
  '/state',
];
const setDeliveryModeFeatureKey = [
  '/internal-api/device-management/v0/scanners/',
  '/delivery-mode-feature-state',
];

export const DeliveryModeUpdateDrawer: React.FC<DeliveryModeChangeProps> = ({
  devices,
  onSuccessfulUpdate,
  open,
  handleClose,
  currentOption,
}) => {
  const { t } = useTranslation();
  const { spacing } = useTheme();

  const [recipientConfirmationStateMap, setRecipientConfirmationStateMap] = useState<
    Record<string, RecipientConfirmationState>
  >({});
  const [deliveryModeFeatureStateMap, setDeliveryModeFeatureStateMap] = useState<
    Record<string, boolean>
  >({});

  const { isLoading, failedDevices, updateDevices, isUnauthorized } = useUpdateDevices(
    devices,
    currentOption === 'setRecipientConfirmation'
      ? setRecipientConfirmationKey
      : setDeliveryModeFeatureKey,
    open,
  );
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertTitle, setAlertTitle] = useState('');

  const handleCloseDrawer = () => {
    setRecipientConfirmationStateMap({});
    setDeliveryModeFeatureStateMap({});
    handleClose();
  };

  const getMessageKeys = () => {
    const titleKey =
      currentOption === 'setRecipientConfirmation'
        ? 'successfullySetRecipientConfirmationTitle'
        : `successfullyFeatureChangedTitle`;

    const messageKey =
      currentOption === 'setRecipientConfirmation'
        ? 'successfullySetRecipientConfirmationDescription'
        : 'successfullyFeatureChangedDescription';

    return { titleKey, messageKey };
  };

  const onUpdateSuccesfull = () => {
    setShowSuccessMessage(true);
    const { titleKey, messageKey } = getMessageKeys();
    const updatedDeviceCount =
      currentOption === 'setRecipientConfirmation'
        ? Object.keys(recipientConfirmationStateMap).length
        : Object.keys(deliveryModeFeatureStateMap).length;

    setAlertTitle(t(`deliveryModeUpdateDrawer.${titleKey}`));
    setAlertMessage(
      t(`deliveryModeUpdateDrawer.${messageKey}`, {
        count: updatedDeviceCount,
      }),
    );
    handleCloseDrawer();
    onSuccessfulUpdate();
  };

  const createSetRecipientConfirmationBody = (device: Device) => {
    const body = {
      state: recipientConfirmationStateMap[device.id],
    };

    return JSON.stringify(body);
  };

  const handleUpdateSetRecipientConfirmation = async (devicesToUpdate: Device[]) => {
    await updateDevices(
      devicesToUpdate,
      (device) => device?.usageAgreement?.id || '',
      createSetRecipientConfirmationBody,
      onUpdateSuccesfull,
    );
  };

  const createSetDeliveryModeBody = (device: Device) => {
    const body = {
      scannerId: device.id,
      deliveryModeFeatureEnabled: deliveryModeFeatureStateMap[device.id],
    };

    return JSON.stringify(body);
  };

  const handleUpdateDeliveryModeFeature = async (devicesToUpdate: Device[]) => {
    await updateDevices(
      devicesToUpdate,
      (device: Device) => device.id,
      createSetDeliveryModeBody,
      onUpdateSuccesfull,
    );
  };

  const getDevicesForRecipientConfirmationUpdate = () => {
    return devices.filter((device) => recipientConfirmationStateMap[device.id]);
  };

  const getDevicesForDeliveryModeFeatureUpdate = () => {
    return devices.filter((device) => deliveryModeFeatureStateMap[device.id] !== undefined);
  };

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setShowSuccessMessage(false)}
        open={showSuccessMessage}
      >
        <Alert
          icon={<CheckmarkCircleFilled />}
          onClose={() => setShowSuccessMessage(false)}
          severity="success"
          sx={{
            width: '100%',
            backgroundColor: 'secondary',
            color: 'primary',
            '& .MuiAlert-icon': {
              color: 'primary',
            },
            boxShadow: 'none',
            '& .MuiAlert-action': {
              display: 'none',
            },
          }}
        >
          <Typography fontWeight="bold" variant="subtitle1">
            {alertTitle}
          </Typography>
          <Typography variant="body2">{alertMessage}</Typography>
        </Alert>
      </Snackbar>
      <Drawer
        PaperProps={{
          sx: { width: { xs: '100%', sm: 450 } },
        }}
        anchor="right"
        data-testid="DeliveryModeUpdateDrawer"
        onClose={handleCloseDrawer}
        open={open}
      >
        <HeaderSpace />
        {!isLoading && failedDevices.length === 0 && devices.length > 0 && (
          <>
            <DeviceInfoHeader
              onClose={handleCloseDrawer}
              title={t('deliveryModeUpdateDrawer.title', { count: devices.length })}
            />
            <Divider />
            <Spacer height={spacing(1)} />
          </>
        )}

        {isLoading && <LoadingSpinner />}
        {!isLoading && failedDevices.length > 0 && (
          <>
            <Box sx={{ justifyContent: 'right', px: 3, py: 1, display: 'flex' }}>
              <IconButton onClick={handleCloseDrawer} size="small">
                <GridCloseIcon />
              </IconButton>
            </Box>

            <RetryFailedUpdates
              failedDevices={failedDevices}
              handleUpdate={
                currentOption === 'setRecipientConfirmation'
                  ? handleUpdateSetRecipientConfirmation
                  : handleUpdateDeliveryModeFeature
              }
              isUnauthorized={isUnauthorized}
            />
          </>
        )}

        {!isLoading && failedDevices.length === 0 && devices.length > 0 && (
          <Box px={3} py={1}>
            {currentOption === 'setRecipientConfirmation' && (
              <SetRecipientConfirmationState
                devices={devices}
                setRecipientConfirmationStateMap={setRecipientConfirmationStateMap}
              />
            )}

            {currentOption === 'setDeliveryModeFeature' && (
              <SetDeliveryModeFeature
                devices={devices}
                setDeliveryModeFeatureStateMap={setDeliveryModeFeatureStateMap}
              />
            )}

            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button onClick={handleCloseDrawer} variant="outlined">
                {t('deliveryModeUpdateDrawer.cancelButton')}
              </Button>
              <Button
                color="primary"
                disabled={
                  (currentOption === 'setDeliveryModeFeature' &&
                    Object.keys(deliveryModeFeatureStateMap).length === 0) ||
                  (currentOption === 'setRecipientConfirmation' &&
                    Object.keys(recipientConfirmationStateMap).length === 0)
                }
                onClick={() =>
                  currentOption === 'setRecipientConfirmation'
                    ? handleUpdateSetRecipientConfirmation(
                        getDevicesForRecipientConfirmationUpdate(),
                      )
                    : handleUpdateDeliveryModeFeature(getDevicesForDeliveryModeFeatureUpdate())
                }
                variant="contained"
              >
                {currentOption === 'setRecipientConfirmation'
                  ? t('deliveryModeUpdateDrawer.confirmEndButton')
                  : t('deliveryModeUpdateDrawer.updateButton')}
              </Button>
            </Box>
          </Box>
        )}
      </Drawer>
    </Box>
  );
};
