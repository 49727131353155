import { CircleInformationOutline } from '@deepup/icons';
import { FormControlLabel, Radio, RadioGroup, Stack, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import { Device, RecipientConfirmationState } from 'src/models/device';

const DeviceGroupTooltip = ({
  devices,
  children,
}: {
  devices: Device[];
  children: React.ReactNode;
}) => (
  <Tooltip arrow placement="top" title={devices.map((device) => device.serialNumber).join(', ')}>
    <Box component="span">{children}</Box>
  </Tooltip>
);

const DeviceGroupHeader = ({ devices, title }: { devices: Device[]; title: string }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <DeviceGroupTooltip devices={devices}>
      <CircleInformationOutline color="primary" height={24} width={24} />
    </DeviceGroupTooltip>
    <Typography>{title}</Typography>
  </Box>
);

export const SetRecipientConfirmationState = ({
  setRecipientConfirmationStateMap,
  devices,
}: {
  setRecipientConfirmationStateMap: (
    fn: (
      prevState: Record<string, RecipientConfirmationState>,
    ) => Record<string, RecipientConfirmationState>,
  ) => void;
  devices: Device[];
}) => {
  const { t } = useTranslation();

  const deviceGroups = {
    active: devices.filter(
      (device) =>
        device.deliveryModeFeatureEnabled === true &&
        device.deliveryModeState === 'DELIVERY_MODE_STATE_OFF',
    ),
    inReview: devices.filter(
      (device) =>
        device.deliveryModeFeatureEnabled === true &&
        device.deliveryModeState === 'DELIVERY_MODE_STATE_IN_REVIEW',
    ),
    ended: devices.filter(
      (device) =>
        device.deliveryModeFeatureEnabled === true &&
        device.deliveryModeState === 'DELIVERY_MODE_STATE_ON',
    ),
    disabled: devices.filter((device) => device.deliveryModeFeatureEnabled === false),
  };

  const handleDevicesChange = (devices: Device[], value: RecipientConfirmationState) => {
    setRecipientConfirmationStateMap((prevState: Record<string, RecipientConfirmationState>) => {
      const updates = devices.reduce<Record<string, RecipientConfirmationState>>(
        (acc, device) => ({
          ...acc,
          [device.id]: value,
        }),
        {},
      );

      return { ...prevState, ...updates };
    });
  };

  const removeDevices = (devices: Device[]) => {
    setRecipientConfirmationStateMap((prevState: Record<string, RecipientConfirmationState>) => {
      const newState = { ...prevState };

      devices.forEach((device) => {
        delete newState[device.id];
      });

      return newState;
    });
  };

  return (
    <Stack gap={2}>
      <Typography variant="h6">
        {t('deliveryModeUpdateDrawer.setRecipientConfirmationState')}
      </Typography>

      {deviceGroups.active.length > 0 && (
        <Box>
          <DeviceGroupHeader
            devices={deviceGroups.active}
            title={t('deliveryModeUpdateDrawer.activeDevices', {
              count: deviceGroups.active.length,
            })}
          />
          <RadioGroup
            defaultValue="RECIPIENT_CONFIRMATION_STATE_ACTIVE"
            sx={{ px: 4, pb: 2, pt: 1 }}
          >
            <FormControlLabel
              control={<Radio />}
              label={`${t('deliveryModeUpdateDrawer.recipientConfirmationOptions.active')} (${t('deliveryModeUpdateDrawer.current')})`}
              onChange={() => removeDevices(deviceGroups.active)}
              value="RECIPIENT_CONFIRMATION_STATE_ACTIVE"
            />
            <FormControlLabel
              control={<Radio />}
              label={t('deliveryModeUpdateDrawer.recipientConfirmationOptions.inReview')}
              onChange={() =>
                handleDevicesChange(deviceGroups.active, 'RECIPIENT_CONFIRMATION_STATE_IN_REVIEW')
              }
              value="RECIPIENT_CONFIRMATION_STATE_IN_REVIEW"
            />
            <FormControlLabel
              control={<Radio />}
              label={t('deliveryModeUpdateDrawer.recipientConfirmationOptions.ended')}
              onChange={() =>
                handleDevicesChange(deviceGroups.active, 'RECIPIENT_CONFIRMATION_STATE_ENDED')
              }
              value="RECIPIENT_CONFIRMATION_STATE_ENDED"
            />
          </RadioGroup>
        </Box>
      )}

      {deviceGroups.inReview.length > 0 && (
        <Box>
          <DeviceGroupHeader
            devices={deviceGroups.inReview}
            title={t('deliveryModeUpdateDrawer.inReviewDevices', {
              count: deviceGroups.inReview.length,
            })}
          />
          <RadioGroup
            defaultValue="RECIPIENT_CONFIRMATION_STATE_IN_REVIEW"
            sx={{ px: 4, pb: 2, pt: 1 }}
          >
            <FormControlLabel
              control={<Radio />}
              label={`${t('deliveryModeUpdateDrawer.recipientConfirmationOptions.inReview')} (${t('deliveryModeUpdateDrawer.current')})`}
              onChange={() => removeDevices(deviceGroups.inReview)}
              value="RECIPIENT_CONFIRMATION_STATE_IN_REVIEW"
            />
            <FormControlLabel
              control={<Radio />}
              label={t('deliveryModeUpdateDrawer.recipientConfirmationOptions.ended')}
              onChange={() =>
                handleDevicesChange(deviceGroups.inReview, 'RECIPIENT_CONFIRMATION_STATE_ENDED')
              }
              value="RECIPIENT_CONFIRMATION_STATE_ENDED"
            />
          </RadioGroup>
        </Box>
      )}

      {deviceGroups.ended.length > 0 && (
        <Box
          sx={({ palette }) => ({
            '&.MuiBox-root': {
              backgroundColor: `${palette.secondary.light}80`,
            },
            p: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            borderRadius: 1,
          })}
        >
          <DeviceGroupHeader
            devices={deviceGroups.ended}
            title={t('deliveryModeUpdateDrawer.offDevices', { count: deviceGroups.ended.length })}
          />
        </Box>
      )}

      {deviceGroups.disabled.length > 0 && (
        <Box
          sx={({ palette }) => ({
            '&.MuiBox-root': {
              backgroundColor: `${palette.secondary.light}80`,
            },
            p: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            borderRadius: 1,
          })}
        >
          <DeviceGroupHeader
            devices={deviceGroups.disabled}
            title={t('deliveryModeUpdateDrawer.disabledDevicesState', {
              count: deviceGroups.disabled.length,
            })}
          />
        </Box>
      )}
    </Stack>
  );
};
