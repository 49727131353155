import { deviceManagementApiV0 } from '@deepup/apis';

import { Address } from './address';
import { Asset } from './asset';
import { Organization } from './organization';

export const activityStatus = ['inUse', 'active', 'inactive'] as const;
export type ActivityStatus = (typeof activityStatus)[number];

export const boolFilterOptions = ['BOOL_FILTER_TRUE', 'BOOL_FILTER_FALSE'] as const;
export type BoolFilterQueryParam = (typeof boolFilterOptions)[number];

export const boolFilterToBoolean = (boolFilter: BoolFilterQueryParam) => {
  return boolFilter === 'BOOL_FILTER_TRUE';
};

export type Device = {
  id: string;
  serialNumber: string;
  clientOrganization: Organization | null;
  latestAsset: Asset | null;
  latestAddress: Address | null;
  activityStatus: ActivityStatus;
  deliveryModeState: DeliveryModeState;
  usageAgreement: UsageAgreement | null;
  deliveryModeFeatureEnabled?: boolean;
};

export type InternalDeviceResponse = {
  scanner: Device;
  deliveryModeFeatureEnabled: boolean;
};

export const unwrapInternalDeviceResponse = (internalDevice: InternalDeviceResponse): Device => ({
  ...internalDevice.scanner,
  deliveryModeFeatureEnabled: internalDevice.deliveryModeFeatureEnabled,
});

export type UsageAgreement = Omit<deviceManagementApiV0.UsageAgreement, 'createdAt'> & {
  createdAt?: string; // ISO 8601
};

export const deliveryModeState = [
  'DELIVERY_MODE_STATE_UNSPECIFIED',
  'DELIVERY_MODE_STATE_ON',
  'DELIVERY_MODE_STATE_OFF',
  'DELIVERY_MODE_STATE_IN_REVIEW',
] as const;
export type DeliveryModeState = (typeof deliveryModeState)[number];

export const recipientConfirmationState = [
  'RECIPIENT_CONFIRMATION_STATE_UNSPECIFIED',
  'RECIPIENT_CONFIRMATION_STATE_ACTIVE',
  'RECIPIENT_CONFIRMATION_STATE_IN_REVIEW',
  'RECIPIENT_CONFIRMATION_STATE_ENDED',
] as const;
export type RecipientConfirmationState = (typeof recipientConfirmationState)[number];

// The UsageOrganizationName is the same as usageAgreement.customerName
export type UsageOrganizationName = string;
