import './setupErrorTracking';
import { LicenseInfo } from '@mui/x-license';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import './main.css';
import { useEnvironment } from './hooks/useEnvironment';

const renderApp = () => createRoot(document.getElementById('root') as HTMLElement).render(<App />);

const enableMocking = async () => {
  const { worker } = await import('../__mocks__/browser');

  return worker.start();
};

// eslint-disable-next-line react-hooks/rules-of-hooks
const { isDev, enableMockServiceWorker, muiProLicenceKey } = useEnvironment();

LicenseInfo.setLicenseKey(muiProLicenceKey);

const useMockServiceWorker = isDev && enableMockServiceWorker;

if (useMockServiceWorker) {
  // mock api must be enabled before app is rendered
  enableMocking().then(renderApp);
} else {
  // render app normally
  renderApp();
}
